import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import ButtonUnderline from "../Button/ButtonUnderline";

const About = ({ headingLevel, location, className }) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <section className={`relative ${className || "mb-20 md:mb-0"}`}>
      <div className="container">
        <div className="grid items-center gap-y-7 md:min-h-[774px] md:grid-cols-2 md:gap-x-6 lg:gap-x-10">
          <div className="order-2 md:order-1">
            <div className="relative z-10 hidden md:block">
              <StaticImage
                src="../../images/1.0 Homepage/About us blue.png"
                loading="lazy"
                width={560}
              />
            </div>
            <div className="left-0 top-0 hidden h-full md:absolute md:block md:w-[30vw]">
              <StaticImage
                src="../../images/1.0 Homepage/About Us yellow.jpg"
                loading="lazy"
                width={560}
                className="h-full"
                imgClassName="object-right"
              />
            </div>

            <div className="relative z-10 md:hidden">
              <StaticImage
                src="../../images/1.0 Homepage/3.0 About us mobile.png"
                loading="lazy"
                width={560}
              />
            </div>
          </div>
          <div className="order-1 md:order-2">
            <HeadingTag>What We’re About</HeadingTag>
            <p>
              We’re one of the few firms in San Diego County that focuses
              exclusively on probate, trusts and estates, and we’ve been doing
              it for over 30 years. Our award-winning attorneys are known for
              being compassionate professionals and skilled problem solvers.
            </p>
            <ButtonUnderline href="/our-firm/" text="Learn More About Us" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
