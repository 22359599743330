import React, { useRef, useState } from "react";
import Slick from "react-slick";
import styled from "@emotion/styled";
import tw from "twin.macro";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledSlider = styled.div`
  .slick-track {
    ${tw`ml-0 flex!`}
  }
  .slick-slide {
    ${tw`h-auto!`}
  }
  .slick-slide > div {
    ${tw`mx-3 md:mx-3 h-full`}
  }
  .slick-list {
    ${tw`-mx-3 md:-mx-3 p-0!`}
  }
  .slick-arrow {
    ${tw`text-xl transform-none top-[30%]`}
  }
  .slick-prev {
    ${tw`-left-12`}
  }
  .slick-next {
    ${tw`-right-12`}
  }
`;

const Slider = ({ children, slideCount }) => {
  const slider = useRef();

  const [progressWidth, setProgressWidth] = useState(0);

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className="slick-prev slick-arrow"
        aria-label="Previous Slide"
      >
        <i className="fa-solid fa-chevron-left text-seafoam"></i>
      </button>
    );
  }

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className="slick-next slick-arrow"
        aria-label="Next Slide"
      >
        <i className="fa-solid fa-chevron-right text-seafoam"></i>
      </button>
    );
  }

  const sliderSettings = {
    dots: false,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    speed: 750,
    autoplay: false,
    autoplaySpeed: 7500,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    swipeToSlide: true,
    beforeChange: (current, next) =>
      setProgressWidth((next / (slideCount - 1)) * 100),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
    ],
  };

  return (
    <StyledSlider className="relative">
      <Slick {...sliderSettings} ref={slider}>
        {children}
      </Slick>

      <div
        className="progress relative mx-auto mt-10 h-1 w-full bg-seafoam/50 md:mt-14"
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div
          className="absolute left-0 top-0 h-1 rounded-[20px] bg-seafoam transition-all duration-300 ease-linear"
          style={{ width: progressWidth + "%" }}
        />
      </div>
    </StyledSlider>
  );
};

export default Slider;
