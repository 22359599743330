import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import ButtonUnderline from "../Button/ButtonUnderline";

const ResourcesCTA = ({ headingLevel, location, className }) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <section
      className={`relative ${className || "mb-20 mt-20 md:mb-0 md:mt-0"}`}
    >
      <div className="container">
        <div className="grid items-center gap-y-7 md:min-h-[774px] md:grid-cols-2 md:gap-x-6 lg:gap-x-10">
          <div className="order-2">
            <div className="relative z-10 hidden md:block">
              <StaticImage
                src="../../images/1.0 Homepage/Resources blue.png"
                loading="lazy"
                width={560}
              />
            </div>
            <div className="right-0 top-0 hidden h-full md:absolute md:block md:w-[30vw]">
              <StaticImage
                src="../../images/1.0 Homepage/Resources yellow.png"
                loading="lazy"
                width={560}
                className="h-full"
                imgClassName="object-right"
              />
            </div>

            <div className="relative z-10 md:hidden">
              <StaticImage
                src="../../images/1.0 Homepage/Resources mobile.png"
                loading="lazy"
                width={560}
              />
            </div>
          </div>
          <div className="order-1">
            <HeadingTag>Helpful Resources</HeadingTag>
            <p>
              From agent representations and conservatorships to property
              transfers and special needs trusts, the estate and probate world
              is multifaceted. Our informative resources can help you make sense
              of it all.
            </p>
            <div className="flex flex-col gap-y-6">
              <ButtonUnderline
                href="/news-and-information/"
                text="News & Insights"
              />
              <ButtonUnderline href="/blogs/" text="Read Our Blog" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ResourcesCTA;
