import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import Badges from "../components/Repeating/Badges";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonUnderline from "../components/Button/ButtonUnderline";
import SliderProgressBar from "../components/Slider/SliderPracticeAreas";
import ResourcesCTA from "../components/Repeating/ResourcesCTA";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="San Diego Estate Planning Firm | Brierton, Jones & Jones"
        description="Brierton, Jones & Jones is a San Diego estate planning law firm with 30+ years’ experience in probate and trust administration, and more. Call us today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="relative bg-gray-50 pt-12 lg:pb-18 lg:pt-20">
        <div className="absolute right-0 top-0 hidden h-full w-full lg:block">
          <StaticImage
            src="../images/1.0 Homepage/Full Width Hero.png"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="container">
          <div className="relative lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-20">
            <div className="pb-8 text-center lg:col-span-7 lg:col-start-1 lg:pb-0 lg:text-left">
              <div className="mb-7 font-heading text-mobile-7xl font-semibold text-typography-heading lg:mb-2.5 lg:text-7xl">
                Protecting you, your family, and{" "}
                <span className="relative z-10 whitespace-nowrap after:lg:absolute after:lg:bottom-3 after:lg:left-0 after:lg:z-[-10] after:lg:h-8 after:lg:w-full after:lg:bg-yellow xl:pr-9">
                  <span className="text-yellow lg:text-typography-heading">
                    your future
                  </span>
                  .
                </span>
              </div>
              <p className="mb-8 font-display text-2xl font-medium lg:mb-10 lg:text-[1.563rem] lg:leading-[1.875rem]">
                Because your legacy deserves the best.
              </p>
              <ButtonSolid
                modal="modal-contact"
                text="Contact"
                className="min-w-[260px] lg:min-w-[180px]"
              />
            </div>

            <div className="hidden lg:col-span-5 lg:col-end-13 lg:block">
              <StaticImage
                src="../images/1.0 Homepage/BJJ mask.png"
                loading="eager"
              />
            </div>
          </div>
        </div>

        <div className="lg:hidden">
          <StaticImage
            src="../images/1.0 Homepage/1.0 Hero mobile.png"
            loading="eager"
            imgClassName="object-top"
          />
        </div>
      </section>

      <section className="relative mb-20 bg-blue pb-16 pt-20 md:mb-32 md:pt-32">
        <div className="container">
          <header className="mx-auto mb-16 max-w-[632px] text-center">
            <h1 className="text-snow">
              A Trust, Estate and Probate Law Firm Serving San Diego and
              Southern California
            </h1>
            <p className="text-gray-50">
              We’re a San Diego County firm with 30+ years of experience in
              estate planning, probate and trust administration,
              conservatorships, guardianships, and trust and estate litigation.
            </p>
            <ButtonSolid
              modal="modal-contact"
              text="Schedule a Consultation"
              className="min-w-[260px] lg:min-w-[180px]"
            />
          </header>
          <Badges />
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="flex items-center justify-between md:space-x-6">
            <header className="mb-8 max-w-[756px] md:mb-14">
              <h2>Our Focus</h2>
              <p className="font-display text-lg md:mb-0 md:text-xl">
                We’re estate, probate and trust attorneys with proven success in
                handling complex issues. We’re also experts in a wide range of
                related practice areas.
              </p>
            </header>
            <div className="hidden md:block">
              <ButtonUnderline
                href="/practice-areas/"
                text="All Practice Areas"
              />
            </div>
          </div>

          <SliderProgressBar slideCount={13}>
            <div className="group inline-block">
              <Link
                to="/trust-estate-litigation-lawyer/"
                className="no-underline"
              >
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/08 Trust Litigation.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-2xl">
                  Trust Litigation
                </h3>
              </Link>
            </div>
            <div className="group inline-block">
              <Link to="/trust-administration-lawyer/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/03 Trust Administration.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-2xl">
                  Trust Administration
                </h3>
              </Link>
            </div>
            <div className="group inline-block">
              <Link
                to="/estate-planning-attorney-san-diego/"
                className="no-underline"
              >
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/01 Estate Planning.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-2xl">
                  Estate Planning
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/conservatorship-lawyer/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/04 Conservatorship.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-2xl">
                  Conservatorship
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/probate-attorney-san-diego/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/02 Probate.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-2xl">
                  Probate
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/guardianship-lawyer/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/05 Guardianship.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-2xl">
                  Guardianship
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/special-needs-trust-lawyer/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/06 Special Needs Trust.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-2xl">
                  Special Needs Trust
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/probate-litigation-lawyer/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/07 Probate Litigation.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-2xl">
                  Probate Litigation
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/trust-estate-mediation/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/09 Trust _ Estate Mediation.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-2xl">
                  Trust and Estate Mediation
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link
                to="/parent-grandparent-child-exclusions/"
                className="no-underline"
              >
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/10.0 Real Property Tax Consultation.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-2xl">
                  Property Transfers
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/estate-tax-returns/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/11 Estate Tax Returns.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-2xl">
                  Estate Tax Returns
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/trust-accountings/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/12 Accountings.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-2xl">
                  Accountings
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/agent-representation/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/13 Agent Representation.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-2xl">
                  Agent Representation
                </h3>
              </Link>
            </div>
          </SliderProgressBar>

          <div className="mt-10 md:hidden">
            <ButtonUnderline
              href="/practice-areas/"
              text="All Practice Areas"
            />
          </div>
        </div>
      </section>

      <WhyUs />
      <Attorneys className="md:!mb-0" />
      <About />
      <Testimonials />
      <ResourcesCTA />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
